// @import 'settings.scss';

nav.nav.tab-nav {
    padding-top: 4px;

    .nav-link {
        color: $gray-800;
        .badge {
            font-size: 85%;
            margin-left: 0.25em;
            background-color: $gray-300;
            border-radius: 40%;
            padding-bottom: 0.15em;
            padding-top: 0.3em;
            font-weight: normal;
        }
    }

    .nav-link.active {
        font-weight: bold;
        border-bottom: 2px solid $gray-600;
        .badge {
            font-weight: bold;
        }
    }
}
