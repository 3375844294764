::placeholder,
::-ms-input-placeholder,
:-ms-input-placeholder {
    color: #adb5bd;
}

.input-group {
    select:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
}

.input-group {
    ng-select:not(:last-child) .ng-select-container {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: none;
    }

    ng-select:not(:first-child) .ng-select-container {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}

ng-select .ng-select-container {
    height: calc(1.5em + 0.75rem + 2px) !important;
    font-size: 1rem;
}
.ng-select .ng-select-container .ng-value-container .ng-placeholder {
    color: #adb5bd;
}

// Validation
ng-select.is-invalid .ng-select-container {
    border-color: theme-color('danger');
}

.ng-select.ng-select-disabled > .ng-select-container {
    background-color: #e9ecef;
}

.ng-select.custom.custom-sm {
    .ng-select-container {
        min-height: 10px;
        @extend .custom-select-sm !optional;
        border-radius: 0.2em;
        .ng-value-container {
            padding-left: 0;
        }
    }

    ng-dropdown-panel {
        font-size: small;
    }
}

arv-validation-messages .invalid-feedback {
    display: block;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: red;
}
