$input-placeholder-color: $gray-500 !important;
$theme-colors: (
    'primary': #097aff,
    'secondary': #ff097c,
    'dark': #1c1d2e,
);

$font-family-sans-serif: 'Roboto',
    // Basic web fallback
'Helvetica Neue',
Arial, sans-serif,
    // Emoji fonts
'Apple Color Emoji',
'Segoe UI Emoji', 'Segoe UI Symbol' !default;
