/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700&display=swap');

@import 'primeicons/primeicons';
@import 'primeng/resources/themes/nova/theme';
@import 'primeng/resources/primeng.min';
@import 'scss/settings';
@import 'bootstrap/scss/bootstrap';

@import '~@ng-select/ng-select/themes/default.theme.css';
@import 'ngx-toastr/toastr';

@import 'scss/spinners';
@import 'scss/splashScreen';
@import 'scss/modals';
@import 'scss/forms';
@import 'scss/links';
@import 'scss/timeline';
@import 'scss/stars';
@import 'scss/cards';
@import 'scss/buttons';
@import 'scss/tabNav';
@import 'scss/badges';
@import 'scss/tableTransitions';
@import 'scss/primeNgLite';

@import 'scss/paymentModal';
@import 'scss/dataExportModal';

body {
  overflow-x: hidden;
}

// Emphasis bold rather than italics.
em {
  font-style: normal;
  font-weight: bold;
}

.bg-success-light {
    background-color: lighten($success, 57%) !important;
}

// Google places dropdown
.pac-container {
    z-index: 10000 !important;
}

// Query-builder
.query-builder-dropdown {
    font-size: 0.85rem !important;

    .dropdown-item {
        padding: 0.25rem 1rem !important;
    }
}

.badge {
    line-height: 1.5 !important;
}

.font-body {
    font-family: $font-family-sans-serif;
    font-weight: $font-weight-normal;
}

.shadow-inset {
    -moz-box-shadow: inset 0 0 10px -5px #000000;
    -webkit-box-shadow: inset 0 0 10px -5px #000000;
    box-shadow: inset 0 0 10px -5px #000000;
}

.shadow-sm-inset {
    -moz-box-shadow: inset 0 0 8px -6px #000000;
    -webkit-box-shadow: inset 0 0 8px -6px #000000;
    box-shadow: inset 0 0 8px -6px #000000;
}

.shadow-sm-invert {
    box-shadow: 0 -0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
    -webkit-box-shadow: 0 -0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
    -moz-box-shadow: 0 -0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-invert {
    box-shadow: 0 -0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    -webkit-box-shadow: 0 -0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    -moz-box-shadow: 0 -0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.animate-shadow {
    transition: box-shadow 350ms ease-in-out, border 250ms ease-in-out;
}

.text-underline-dashed {
    text-decoration: underline;
    text-decoration-style: dotted;
    cursor: help;
}

.visually-hidden{
    display: none;
}

ngb-modal-backdrop {
    z-index: 1050 !important;
}

.p-datatable {
    .p-datatable-scrollable-table {
        & >.p-datatable-thead {
            top: -1px;
        }
    }
}

.min-vh-95 {
    min-height: 95vh !important;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    background: #ffffff;
    color: #333333;
}

.p-column-filter-buttonbar {
    button {
        &:first-child {
            margin-right: 10px;
        }
    }
}

.p-datepicker table th {
    text-align: center;
}

.datetimepicker-small {
    z-index: 1026 !important;
}

div.p-datepicker {
    &.datetimepicker-small {
        padding: 0;

        .p-timepicker {
            padding: 0;
        }
    }
}

input {
    &:placeholder-shown {
        text-overflow: ellipsis;
    }
}

/* Temporary fix */
/* ToDo: Remove when bootstrap is updated to version 5.x */
.btn-close:hover {
    color: #000;
    text-decoration: none;
    opacity: .75;
}
.alert-dismissible .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 1rem;
}
.btn-close {
    box-sizing: content-box;
    width: 1em;
    height: 1em;
    padding: .25em;
    color: #000;
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
    border: 0;
    border-radius: .375rem;
    opacity: .5;
}
