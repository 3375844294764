.card {
    &.hover-card-shadow-sm {
        transition: box-shadow 200ms ease-in-out;
    }
    &.hover-card-grow {
        transition: transform 200ms ease-in-out;
    }

    &.hover-card-all {
        transition: box-shadow 200ms ease-in-out, transform 200ms ease-in-out;
    }

    &.hover-card-pointer:hover {
        cursor: pointer;
    }
    &.hover-card-shadow-sm:hover {
        @extend .shadow-sm;
    }
    &.hover-card-grow:hover {
        transform: scale(1.1);
    }
    &.hover-card-all:hover {
        cursor: pointer;
        @extend .shadow-sm;
        transform: scale(1.1);
    }
}
