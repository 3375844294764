ngb-modal-window.transparent-dialog {
    div.modal-content {
        background: none;
        border: none;
    }
}

ngb-modal-window.shadow-sm {
    div.modal-content {
        @extend .shadow-sm;
    }
}

ngb-modal-window.shadow-lg {
    div.modal-content {
        @extend .shadow-lg;
    }
}

ngb-modal-window.border-0 {
    div.modal-content {
        border: none;
    }
}

ngb-modal-window.animate-bounce-fade .modal-content {
    animation-name: animate-bounce-fade;
    animation-duration: 450ms;
}

@keyframes animate-bounce-fade {
    0% {
        transform: scale(0.5);
        opacity: 0;
    }
  75% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.modal-dialog.fullpage .modal-content {
  height: calc(100vh - 50px) !important;
}
