.schedule-timeline {
    display: block;
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
}

.schedule-timeline-icon {
    position: relative;
    width: 32px;
    height: 32px;
    border-radius: 32px;
    display: flex;
    align-items: center;
    float: left;
    margin: 3px 20px 0 0;

    fa-icon {
        margin: 0 auto;
    }
}

.schedule-timeline-item {
    text-align: left;
    padding-top: 12px;
    padding-bottom: 12px;
    display: flex;
    align-items: center;
}

.schedule-timeline-item:not(:first-child) {
    .schedule-timeline-icon::before {
        content: '';
        border-left: 2px dotted #e2e2e2;
        position: absolute;
        top: -100%;
        height: 100%;
        left: 15px;
    }

    &.actual .schedule-timeline-icon::before {
        border-left-style: solid;
        border-left-color: theme-color('success');
    }
}

.schedule-timeline-item.with-extra {
    .schedule-timeline-icon::after {
        content: '';
        border-left: 2px dotted #e2e2e2;
        position: absolute;
        top: 100%;
        height: calc(100% + 4px);
        left: 15px;
    }

    .schedule-timeline-item-extra {
        position: relative;
        top: 12px;
        margin-left: 52px;
    }
}

.schedule-timeline-item.actual .schedule-timeline-icon::after {
    content: '';
    border-left: 2px solid theme-color('success');
    position: absolute;
    top: 100%;
    height: 50%;
    left: 15px;
    z-index: 1000;
}
