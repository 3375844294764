@import 'node_modules/bootstrap/scss/_functions';
@import 'node_modules/bootstrap/scss/_variables';
@import 'variables';

$grid-breakpoints: (
    xxxs: 0,
    xxs: 320px,
    xs: 568px,
    sm: 667px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1440px,
    xxxl: 1600px,
);
$container-max-widths: (
    xxxs: 0,
    xxs: 320px,
    xs: 568px,
    sm: 667px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
);

@each $break, $width in $grid-breakpoints {
    .w-#{$break} {
        width: $width;
        max-width: $width;
        min-width: $width;
    }
}
