@each $color, $value in $theme-colors {
    .btn-outline-background-#{$color} {
        color: theme-color($color) !important;
        border-color: theme-color($color) !important;
        border-width: 2px !important;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
            border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
        background-color: color-yiq(theme-color($color)) !important;
    }

    .btn-outline-background-#{$color}:hover {
        color: color-yiq(theme-color($color)) !important;
        background-color: theme-color($color) !important;
    }
}
