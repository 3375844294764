body .p-component,
.p-inputtext, .p-menuitem-text, .p-menuitem-link {
    font-family: $font-family-sans-serif;
    font-size: 14px;
}

body .p-datatable .p-datatable-tbody > tr > td,
body .p-datatable .p-datatable-thead > tr > th,
body .p-datatable .p-datatable-tfoot > tr > th,
body .p-datatable .p-datatable-tfoot > tr > td,
body .p-datatable .p-datatable-footer > tr > th,
body .p-datatable .p-datatable-footer > tr > td,
body .p-paginator,
body .p-datatable .p-datatable-caption,
body .p-datatable .p-datatable-header,
body .p-datatable .p-datatable-summary body .p-datatable .p-datatable-tfoot,
body .p-datatable .p-datatable-footer {
    border-color: #dfdfdf !important;
}

body .p-datatable-table .p-datatable-thead > tr > th,
body .p-datatable-table .p-datatable-thead > tr > td,
body .p-datatable .p-datatable-tfoot > tr > th,
body .p-datatable .p-datatable-tfoot > tr > td,
body .p-datatable .p-datatable-tbody > tr:nth-child(even),
body .p-paginator,
body .p-datatable .p-datatable-caption,
body .p-datatable .p-datatable-summary {
    background-color: #f8f9fa !important;
}

body .p-datatable .p-sortable-column.p-state-highlight,
body .p-datatable .p-sortable-column.p-state-highlight .p-sortable-column-icon {
    color: #333333;
}

.p-datatable .p-sortable-column.p-highlight,
.p-datatable .p-sortable-column.p-highlight:hover {
    color: #333333;
}

.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon,
.p-datatable .p-sortable-column.p-highlight:hover .p-sortable-column-icon {
    color: #848484;
}

body .p-inputgroup p-dropdown {
    display: flex;
    align-items: center;
    justify-content: center;
}

.custom-overlaypanel {
    &.p-overlaypanel {
        .p-overlaypanel-content {
            padding: 0;
        }
    }
}

.p-menu-list,
.p-dropdown-items,
.p-multiselect-items{
    margin-bottom: 0;
}

.p-autocomplete-items {
    margin-bottom: 0;

    .p-autocomplete-empty-message {
        padding: 0.429rem 0.857rem;
    }
}

@media (max-width: 991px) {
    .centered-menu {
        width: calc(100% - 25px);
        a.p-menu-link {
            justify-content: center;
        }
    }
}

.p-inputswitch {
    &.p-inputswitch-checked {
        &:not(.p-disabled):hover {
            .p-inputswitch-slider {
                background: var(--primary);
            }
        }
        .p-inputswitch-slider {
            background: var(--primary);

            &:before {
                background: #d7d7d7;
            }
        }
    }
}

.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
    background: var(--primary);
}

.p-overlaypanel-footer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
}

.p-menuitem-link {
    &:hover {
        text-decoration: none;
    }
}

.p-menuitem-text {
    color: #495057;
}

.p-dropdown-panel {
    p-dropdownitem:first-child {
        .p-dropdown-item {
            &:not(:hover):not(.p-highlight) {
                background: none;
                color: unset;
            }
        }
    }
}

.p-menu-list {
    .p-menuitem {
        &:first-child {
            .p-menuitem-content {
                &:not(:hover) {
                    background: none;
                    color: unset;
                }
            }
        }
    }
}

.p-multiselect-panel {
    p-multiselectitem {
        .p-multiselect-item {
            &.p-focus {
                background: unset;

                &.p-highlight {
                    background: #007ad9;
                }

                &:not(.p-highlight) {
                    background: unset;
                    &:hover {
                        background: #eaeaea;
                    }
                }
            }
        }
    }
}

