.star {
    color: lightgray;
    cursor: pointer;
    &.star-active {
        color: goldenrod !important;
    }

    &:not(.star-active):hover {
        color: goldenrod !important;
    }
}
